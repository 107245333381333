import * as React from 'react';

import {useLocation} from '@reach/router';
import {Helmet} from 'react-helmet';
import {Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import EditorialTemplate from 'src/templates/editorial';
import {PodcastArticleTemplate} from 'src/templates/editorial/PodcastArticle';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components';
import useSWR from 'swr';

const ErrorWrapper = styled('div').attrs({
  pt: 'L',
  pb: 'L',
})`
  background-color: ${({theme}) => theme.colors.white};
`;

interface EditorialDraftPageProps {
  params: {
    slug: string;
  };
}

const groqQuery = `
  *[
    _type=="editorial"
    && slug.current == $slug && (
      _id in path("drafts.**") || !defined(*[_id == "drafts." + ^._id][0])
    )
  ][0] {
    ...,
    author->,
    articleAuthor->,
    heroImage {
      ...,
      asset->
    },
    editorialCategory->,
    description->,
    episodeImage{
      ...,
      asset->
    }
  }
`;

const EditorialDraftPage: React.FC<EditorialDraftPageProps> = ({params}: EditorialDraftPageProps) => {
  const {data, error} = useSWR([groqQuery, params.slug], (query, slug) => sanityClient.fetch(query, {slug}));
  const location = useLocation();
  if (error) {
    return (
      <ErrorWrapper>
        <Typography.H1>Error loading draft editorial:</Typography.H1>
        <Typography.P1>{JSON.stringify(error)}</Typography.P1>
      </ErrorWrapper>
    );
  }

  if (data === undefined) {
    return (
      <Container>
        <Typography.H2 mt="L" mb="L" color="white" textAlign="center">
          Loading draft preview
        </Typography.H2>
      </Container>
    );
  }

  if (data === null) {
    return (
      <Container>
        <Typography.P0 color="white">Sorry, we could not find a page with the provided slug</Typography.P0>
        <Typography.P0 color="white">
          If you believe this is an error, make sure you are logged into sanity.
        </Typography.P0>
      </Container>
    );
  }

  if (!data.isPodcast) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <EditorialTemplate isDraft location={location} href={location.href} editorial={data} />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <PodcastArticleTemplate
          isDraft={true}
          podcastEditorial={data}
          megaphoneLink={data.megaphoneLink}
          podcastDescription={data.podcastDescription}
          podcastTranscript={data.podcastTranscript}
          episodeImage={data.episodeImage}
          href={''}
        />
      </>
    );
  }
};

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: 'black',
})``;

export default EditorialDraftPage;
